import React, { useEffect, useState } from "react";
import MainDetail from "./Forms/FormMainDetail";
import MapForm from "./Forms/MapForm";
import { Card, Row, Col, Tag, TimePicker } from "antd";
import Footer from "./Footer/footer";
import SideCart from "./SideCart/SideCart";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { saveCustomer } from "../../store/actions/customeraction";
import { isMobile } from "react-device-detect";
import MobileNavBottom from "../Footer/MobileNavBarBottom";

const DriveOutCheckout = () => {
  let [isformValid, setIsFormValid] = useState(false);
  let [enablePay, setEnablePay] = useState(false);
  let [formSet, setFormSet] = useState(false);
  let [userData, setUserData] = useState({});

  let selfirstName = "";
  let sellastName = "";
  let selphoneNo = "";
  let selemail = "";
  let selcountryCode = "+47";
  let cartLength = useSelector((state) => state.cartReducer.cartItems.length);
  const dispatch = useDispatch();
  let location = useSelector((state) => state.locationReducer.locationAddress);
  let root = useSelector((state) => state.rootReducer);
  let navigate = useNavigate();
  let restaurant = useSelector(
    (state) => state.restaurantReducer.selectedRestaurant
  );

  const onAddCustomer = () => {
    dispatch(
      saveCustomer({
        email: userData.email,
        phone: userData.phoneNo,
        lastName: userData.lastName,
        firstName: userData.firstName,
        countryCode: userData.countryCode,
        locAddress: location
      })
    )
      .then(setEnablePay(true))
      .catch(setFormSet(true));
  };
  const setFormValid = (
    status,
    lastName,
    firstName,
    email,
    phoneNo,
    countryCode
  ) => {
    setUserData({
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNo: phoneNo,
      countryCode: countryCode
    });

    selfirstName = firstName;
    sellastName = lastName;
    selemail = email;
    selphoneNo = phoneNo;
    selcountryCode = countryCode;
    setIsFormValid(status);
  };

  useEffect(() => {
    if (cartLength == 0) {
      navigate("/products", { replace: true });
    }
  }, []);

  return (
    <>
      <div className="formWrapper" id="checkout">
        <Row className="checkoutRow justify-content-evenly">
          <Col xs={24} sm={12} className="p-2">
            {/* <div className="site-card-border-less-wrapper"> */}
            <Card bordered={false} className="cardContainer">
              <Row className="justify-content-between formHeader">
                <Col>Kundedetaljer</Col>
                <Col>
                  <Tag color="#f50">
                    {" "}
                    {root.orderType == "Take Away" ? "Henting" : "Utkjøring"}
                  </Tag>
                </Col>
              </Row>
              <MainDetail
                className="p-0"
                formSet={formSet}
                setFormValid={setFormValid}
                onAddCustomer={onAddCustomer}
              />

              {root.orderType == "Drive Out" && <MapForm />}
              <Footer
                isEnablePay={enablePay}
                formValid={isformValid}
                onAddCustomer={onAddCustomer}
              />
            </Card>
            {/* </div> */}
          </Col>
          <Col xs={24} sm={9} className="productTotalContainer p-2">
            <SideCart isEnablePay={enablePay} />
          </Col>
        </Row>
      </div>
      {isMobile && <MobileNavBottom />}
    </>
  );
};
export default DriveOutCheckout;
