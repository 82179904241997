import React, { useEffect, useState } from "react";
import { Card, Col } from "antd";
import { MdLocationPin } from "react-icons/md";
import { useSelector } from "react-redux";
import { s3url } from "../../helper/endpoint";
import { CloseCircleOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import heart from "../../assets/Heart Icon.png";
import offerTag from "../../assets/offerTag.png";
import MobileNavBottom from "../Footer/MobileNavBarBottom";

const ProductCard = (props) => {
  const [resClosingTime, setResClosingTime] = useState(null);
  let root = useSelector((state) => state.rootReducer);

  let resSplit = props.res.name.split(" ");
  let resName = props.res.name;
  let resStatus = props.res.availability.status;

  let tdDriveOut = props.res.isTreeDriveDriveOutAllowed;
  let tdPickup = props.res.isTreeDrivePickUpAllowed;

  let tdRestDriveOut = props.res.isRestDriveOut;
  let tdRestPickUp = props.res.isRestPickup;

  let driveoutPermission = tdDriveOut && tdRestDriveOut ? true : false;
  let takeawayPermission = tdPickup && tdRestPickUp ? true : false;

  if (resSplit.length > 2) {
    resName = props.res.name.split(" ").slice(0, 2).join(" ") + "....";
  }

  let MaxDiscountTD = Math.max(
    props.res.tdDriveOutDiscountRange.discount1,
    props.res.tdDriveOutDiscountRange.discount2,
    props.res.tdDriveOutDiscountRange.discount3
  );
  let MaxDiscountTDTakeAway = Math.max(
    props.res.tdTakeAwayDiscountRange.discount1,
    props.res.tdTakeAwayDiscountRange.discount2,
    props.res.tdTakeAwayDiscountRange.discount3,
    props.res.tdTakeAwayDiscountRange.discount4
  );

  let maxOfBoth = Math.max(MaxDiscountTD, MaxDiscountTDTakeAway);

  let dayCheck = () => {
    const d = new Date().getDay();
    let weekday = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday"
    ];
    let finalTimming = props.res[weekday[d]].openingTime;
    console.log("finalTimming", finalTimming);
    return finalTimming;
  };
  let flashTag = () => {
    if (driveoutPermission || takeawayPermission) {
      console.log("resStatus", resStatus);
      if (resStatus === false) {
        return (
          <div className={resStatus === false ? "discountTag" : "discountTag"}>
            <p className="mb-0">Åpner: {dayCheck()}</p>
          </div>
        );
      }
    }

    if (driveoutPermission && takeawayPermission) {
      return (
        <>
          {root.orderType === "reserveTable" ? null : (
            <div
              className={
                MaxDiscountTDTakeAway !== 0 ? "discountTag" : "discountTagNone"
              }
            >
              <p className="mb-0">
                <>
                  <img src={heart} alt="Heart" /> &nbsp; Rebate upto:
                  <span className="blinkText">&nbsp;{maxOfBoth}%</span>
                </>
              </p>
            </div>
          )}
        </>
      );
    }
    if (driveoutPermission && !takeawayPermission) {
      return (
        <div className={MaxDiscountTD != 0 ? "discountTag" : "discountTagNone"}>
          <p>
            <img src={heart} /> Rebate upto:{" "}
            <span className="blinkText">&nbsp;{MaxDiscountTD}%</span>
          </p>
        </div>
      );
    }
    if (!driveoutPermission && takeawayPermission) {
      return (
        <div
          className={
            MaxDiscountTDTakeAway != 0 ? "discountTag" : "discountTagNone"
          }
        >
          <p>
            {" "}
            <img src={heart} /> &nbsp; Rebate upto:
            <span className="blinkText">&nbsp;{MaxDiscountTDTakeAway}%</span>
          </p>
        </div>
      );
    }
  };

  let [tags, setTags] = useState([]);

  let [resClass, setResClass] = useState("disabledRes");

  useEffect(() => {
    let tags = [];

    if (root.orderType == "reserveTable") {
      setResClass("");
    }

    if (root.orderType != "reserveTable") {
      if (
        props.res.isTreeDriveDriveOutAllowed == true &&
        props.res.isRestDriveOut == true
      ) {
        tags.push("Utkjøring");
        setResClass("");
      }

      if (
        props.res.isTreeDrivePickUpAllowed == true &&
        props.res.isRestPickup == true
      ) {
        tags.push(" Henting");
        setResClass("");
      }
    }

    setTags(tags);
  }, []);

  return (
    <>
      {isMobile && (
        <Col span={24} className="mobileCardRest mt-3 position-relative">
          {" "}
          <Card
            className={resClass}
            disabled={true}
            title=""
            bordered={false}
            onClick={() => {
              if (props.res.availability.status && resClass == "") {
                props.onResClickHandler(props.res);
              }
            }}
          >
            {props.res.resOfferEnabled && (
              <img
                src={offerTag}
                className="position-absolute offerTagMobile"
                style={{
                  right: 0,
                  top: 0,
                  borderRadius: 0
                }}
              />
            )}{" "}
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={`${s3url}/${props.res.logo}`}
                style={{ width: "100px" }}
              />
              <div className="productDescription">
                <div className="title">{resName}</div>
                {
                  <div className="description text-left">
                    <MdLocationPin className="pinColor" />
                    {props.res.address.city.length < 13
                      ? props.res.address.city
                      : props.res.address.city.substr(0, 13) + "..."}
                  </div>
                }
                <div>{flashTag()}</div>
                <div className="subTitle pt-2">
                  {props.res.availability.status ? (
                    <div className="subTitleTag">
                      {tags.map((e, i) => {
                        console.log("e", e);
                        console.log("i", i);
                        return <p key={i}>{e}</p>;
                      })}
                      {/* {root.orderType && ( */}
                      {root.orderType != "reserveTable" && tags.length < 1 && (
                        <p className="redClosedTag">
                          <CloseCircleOutlined style={{ marginRight: "5px" }} />
                          Stengt
                        </p>
                      )}
                    </div>
                  ) : (
                    <>
                      <p className="redClosedTag">
                        <CloseCircleOutlined style={{ marginRight: "5px" }} />
                        Stengt
                      </p>
                    </>
                  )}{" "}
                  <div></div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      )}
      {!isMobile && (
        <Col className={`col-auto `}>
          <Card
            className={resClass}
            disabled={true}
            title=""
            bordered={false}
            onClick={() => {
              if (props.res.availability.status && resClass == "") {
                props.onResClickHandler(props.res);
              }
            }}
          >
            <div>{flashTag()}</div>

            <div className="d-flex align-items-center justify-content-center position-relative">
              <img
                src={`${s3url}/${props.res.logo}`}
                style={{ width: "150px" }}
              />

              {props.res.resOfferEnabled && (
                <img
                  src={offerTag}
                  className="position-absolute offerTagWeb"
                  style={{ bottom: 0 }}
                />
              )}
            </div>
            <div className="productContainer">
              <div className="title">{resName}</div>
            </div>
            <div className="productDescription">
              {
                <div className="description justify-content-start">
                  <MdLocationPin />
                  {props.res.address.city.length < 13
                    ? props.res.address.city
                    : props.res.address.city.substr(0, 13) + "..."}
                </div>
              }
              <div className="subTitle pt-2">
                {props.res.availability.status ? (
                  <div className="subTitleTag">
                    {tags.map((e) => {
                      return <p>{e}</p>;
                    })}

                    {root.orderType != "reserveTable" && tags.length < 1 && (
                      <p className="redClosedTag">
                        <CloseCircleOutlined style={{ marginRight: "5px" }} />
                        Stengt
                      </p>
                    )}
                  </div>
                ) : (
                  // for restaurant is on holiday or something-----------
                  <>
                    {root.orderType && (
                      <p className="redClosedTag">
                        <CloseCircleOutlined style={{ marginRight: "5px" }} />
                        Stengt
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </Card>
        </Col>
      )}
      {isMobile && <MobileNavBottom />}
    </>
  );
};
export default ProductCard;
