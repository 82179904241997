import React from "react";
import BottomFooter from "../Footer/BottomFooter";
import PrivacyHeader from "../NavbarHeader/PrivacyHeader";
import { isMobile } from "react-device-detect";

export default function ScamAlert() {
  return (
    <>
      {" "}
      {!isMobile && <PrivacyHeader />}
      <section className="mission  p-5" style={{ height: "90vh" }}>
        <div className="container">
          <div className="row privacy-header text-center d-flex justify-content-center">
            <h2 className="display-5 mb-4 ">Scam Alert</h2>
          </div>
        </div>
        <div className="container nav_bg">
          <div className="row privacyText">
            <b className="p-0">Don’t fall for phishing attempts</b>
            <b className="p-0">
              EzyGo customer care will never request sensitive bank information
              from you - such incidents are scams and should be reported
              immediately.
            </b>
            <br /> Please do not share your debit/credit card number, CVV
            number, OTP, UPI/ATM pin, and so on with anyone claiming to be a Ezy
            System AS representative. <br />
            EzyGo does not have any customer care number or helpline. EzyGo or
            its authorized representatives will NEVER ask you to share financial
            details. If you’ve encountered such an incident, please know that it
            is a fraud, and you must report it immediately to stay safe from a
            phishing attempt. For assistance on a EzyGo order, click on the
            ‘Help’ section on the EzyGo app - there is no other help channel
            available.
            {/* <p>For assistance on a EzyGo order, directly 'TAWK' chat with us. There is no other help channel available.</p> */}
          </div>
        </div>
      </section>
      <BottomFooter />
    </>
  );
}
