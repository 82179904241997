import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import {
  Box,
  TextField,
  Button,
  Modal,
  Typography,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Card,
  Grid,
  CardContent
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

import axios from "axios";
import tick from "../../assets/tick.png";

import { apiUrl } from "../../helper/endpoint";
import "./form.css";

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  height: "200px",
  textAlign: "center",
  p: 4
};
export default function TextFieldSizes(props) {
  const [open, setOpen] = useState(true);
  const [value, setValue] = React.useState();
  const [confirmationObj, setConfirmation] = useState({
    confirmation: false,
    firstLoad: true
  });
  function onChange(value) {}

  let inputFormValue = {
    jobRole: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    currentEmployer: "",
    currentPosition: "",
    experience: "",
    orgNumber: "",
    dob: null,
    address: "",
    position1: "",
    position2: "",
    messaage: ""
  };
  let errorObjNew = {
    jobRole: false,
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    currentEmployer: false,
    currentPosition: false,
    experience: false,
    orgNumber: false,
    dob: false,
    address: false,
    position1: false,
    position2: false,
    messaage: false
  };
  const [inputForm, setInputForm] = useState(inputFormValue);
  const [errorNewObj, setErrorNewObj] = useState(errorObjNew);

  let formValidationNew = true;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4
  };
  const handleClose = () => {
    setOpen(false);
    props.onCloseForm2(false);
  };
  const onConfirmation = () => {
    setConfirmation((prevObj) => ({
      ...prevObj,
      confirmation: !prevObj.confirmation
    }));
  };
  const submitform = async (e) => {
    let {
      jobRole,
      firstName,
      lastName,
      email,
      phone,
      currentEmployer,
      currentPosition,
      experience,
      orgNumber,
      dob,
      address,
      position1,
      position2,
      message
    } = inputForm;
    e.preventDefault();
    // console.log("form");
    const newEntry = {
      jobRole: jobRole,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      currentEmployer: currentEmployer,
      currentPosition: currentPosition,
      experience: experience,
      orgNumber: orgNumber,
      dob: dob,
      address: address,
      position1: position1,
      position2: position2,
      message: message
    };
    let formValidationNew = true;
    let keys = Object.keys(newEntry);
    for (let key of keys) {
      if (newEntry[key] == "") {
        setErrorNewObj((prevObj) => ({ ...prevObj, [key]: true }));
        formValidationNew = false;
      } else {
        setErrorNewObj((prevObj) => ({ ...prevObj, [key]: false }));
      }
    }
    if (
      confirmationObj.confirmation == false &&
      confirmationObj.firstLoad == true
    ) {
      await setConfirmation({ confirmation: false, firstLoad: false });
    }
    if (formValidationNew && confirmationObj.confirmation) {
      console.log("submiting form");
      setOpen(false);
      const response = await axios.post(
        `${apiUrl}/api/v1/treeDrive/treedriveemail/contactForm`,
        newEntry
      );
      if (response) {
        console.log(response);
        setOpen(false);
      }
    }
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="formStyle">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Ta kontakt med oss
            </Typography>
            <Button
              onClick={handleClose}
              color="secondary"
              variant="contained"
              id="buttonWrap"
            >
              <CloseIcon />
            </Button>
          </div>

          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid xs={12} sm={6} item>
                  <TextField
                    label="Fornavn"
                    placeholder="Fornavn"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={(e) => {
                      setInputForm((prevState) => ({
                        ...prevState,
                        firstName: e.target.value
                      }));
                    }}
                    value={inputForm.firstName}
                  />
                  {errorNewObj.firstName == true ? (
                    <p className="errorMessage">This field is required</p>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid xs={12} sm={6} item>
                  <TextField
                    label="Etternavn"
                    placeholder="Etternavn"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={(e) => {
                      setInputForm((prevState) => ({
                        ...prevState,
                        lastName: e.target.value
                      }));
                    }}
                    value={inputForm.lastName}
                  />
                  {errorNewObj.lastName == true ? (
                    <p className="errorMessage">This field is required</p>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid xs={12} sm={6} item>
                  <TextField
                    label="E-Post"
                    placeholder="Enter E-Post"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={(e) => {
                      setInputForm((prevState) => ({
                        ...prevState,
                        email: e.target.value
                      }));
                    }}
                    value={inputForm.email}
                  />
                  {errorNewObj.email == true ? (
                    <p className="errorMessage">This field is required</p>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid xs={12} sm={6} item>
                  <TextField
                    label="Telefon"
                    placeholder="Enter Telefon"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={(e) => {
                      setInputForm((prevState) => ({
                        ...prevState,
                        phone: e.target.value
                      }));
                    }}
                    value={inputForm.phone}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 12);
                    }}
                  />
                  {errorNewObj.phone == true ? (
                    <p className="errorMessage">This field is required</p>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid xs={12} sm={6} item>
                  <TextField
                    label="Nåværende arbeidsgiver"
                    placeholder="Nåværende arbeidsgiver"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={(e) => {
                      setInputForm((prevState) => ({
                        ...prevState,
                        currentEmployer: e.target.value
                      }));
                    }}
                    value={inputForm.currentEmployer}
                  />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <TextField
                    label="Nåværende posisjon"
                    placeholder="Nåværende posisjon"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={(e) => {
                      setInputForm((prevState) => ({
                        ...prevState,
                        currentPosition: e.target.value
                      }));
                    }}
                    value={inputForm.currentPosition}
                  />
                </Grid>

                <Grid xs={12} sm={6} item>
                  <TextField
                    label="Erfaring (i år)"
                    placeholder="Erfaring (i år)"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={(e) => {
                      setInputForm((prevState) => ({
                        ...prevState,
                        experience: e.target.value
                      }));
                    }}
                    value={inputForm.experience}
                  />
                </Grid>
                <Grid xs={12} sm={6} item>
                  <TextField
                    label="Org nummer (hvis noen)"
                    placeholder="Org nummer (hvis noen)"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={(e) => {
                      setInputForm((prevState) => ({
                        ...prevState,
                        orgNumber: e.target.value
                      }));
                    }}
                    value={inputForm.orgNumber}
                  />
                </Grid>

                <Grid xs={12} sm={6} item>
                  <TextField
                    label="Skriv adressen din"
                    placeholder="Skriv adressen din"
                    variant="outlined"
                    fullWidth
                    required
                    onChange={(e) => {
                      setInputForm((prevState) => ({
                        ...prevState,
                        address: e.target.value
                      }));
                    }}
                    value={inputForm.address}
                    style={{ width: "100%" }}
                  />
                  {errorNewObj.address == true ? (
                    <p className="errorMessage">This field is required</p>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid xs={12} sm={6} item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="DOB*"
                      onChange={(e) => {
                        let date = e.toLocaleDateString("en-US");
                        setInputForm((prevState) => ({
                          ...prevState,
                          dob: date
                        }));
                      }}
                      value={inputForm.dob}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  {errorNewObj.dob == true ? (
                    <p className="errorMessage">This field is required</p>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid xs={12} sm={12} className="p-2">
                  <InputLabel
                    id="demo-simple-select-label"
                    placeholder="Jobbrolle"
                  >
                    Jobbrolle
                  </InputLabel>
                  <Select
                    style={{ width: "100%" }}
                    label="Jobbrolle"
                    placeholder="Jobbrolle"
                    id="demo-simple-select"
                    onChange={(e) => {
                      setInputForm((prevState) => ({
                        ...prevState,
                        jobRole: e.target.value
                      }));
                    }}
                    value={inputForm.jobRole}
                    displayEmpty
                  >
                    <MenuItem value="driver">Sjåfør</MenuItem>
                    <MenuItem value="cook">Kokk</MenuItem>
                    <MenuItem value="waiter">Servitør</MenuItem>
                    <MenuItem value="seller">Selger(For vårt selskap)</MenuItem>
                    <MenuItem value="administration">
                      Administrasjon (For vårt selskap)
                    </MenuItem>
                  </Select>
                  {errorNewObj.jobRole == true ? (
                    <p className="errorMessage">This field is required</p>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid xs={12} sm={12} className="p-2">
                  <div className="inputWrapper" style={{ textAlign: "left" }}>
                    <div style={{ width: "100%", marginTop: "10px" }}>
                      <label style={{ fontWeight: "500" }}>
                        Hvilken andre stillinger du henser å Søke{" "}
                      </label>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "left",
                      justifyContent: "space-between",
                      display: "flex"
                    }}
                  >
                    <TextField
                      id="standard-basic"
                      label="1."
                      variant="standard"
                      onChange={(e) => {
                        setInputForm((prevState) => ({
                          ...prevState,
                          position1: e.target.value
                        }));
                      }}
                      value={inputForm.position1}
                    />
                    <TextField
                      id="standard-basic"
                      label="2."
                      variant="standard"
                      onChange={(e) => {
                        setInputForm((prevState) => ({
                          ...prevState,
                          position2: e.target.value
                        }));
                      }}
                      value={inputForm.position2}
                    />
                  </div>
                </Grid>
                <Grid xs={12} sm={12} className="p-2">
                  <FormControlLabel
                    control={
                      <Checkbox name="checkedA" onClick={onConfirmation} />
                    }
                    label={
                      <div style={{ textAlign: "left" }}>
                        <span>Ved å klikke godtar og godtar jeg </span>
                        <NavLink to="/privacy" style={{ color: "#0C88D8" }}>
                          {" "}
                          Personvern
                        </NavLink>{" "}
                        and
                        <NavLink to="/terms" style={{ color: "#0C88D8" }}>
                          {" "}
                          T&C.
                        </NavLink>
                      </div>
                    }
                  />
                  {confirmationObj.confirmation == false &&
                  confirmationObj.firstLoad == false ? (
                    <p className="errorMessage">This field is required</p>
                  ) : (
                    ""
                  )}
                  {/* <ReCAPTCHA
                    sitekey="6LfOK1MpAAAAADtK4Sr34KUL5nlfQuFD75LaEMmh"
                    onChange={onChange}
                  /> */}
                </Grid>
                <Grid xs={12} sm={12} className="buttonContainer p-2">
                  <Button
                    variant="contained"
                    onClick={submitform}
                    className="buttonWrap"
                  >
                    Send inn
                  </Button>
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    variant="contained"
                    id="buttonWrap"
                  >
                    Avbryt
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Modal>

      <Modal
        open={!open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style1}>
          <div className="tick">
            <img src={tick} />
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Tusen takk for at du kontakter oss!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}></Typography>
        </Box>
      </Modal>
    </>
  );
}
