import React, { useEffect, useState } from "react";
import { Card, Row, Col, Descriptions, Divider } from "antd";
import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";
import { NavLink, useSearchParams } from "react-router-dom";
import {
  checkOrderStatus,
  refundCouponValue
} from "../../store/actions/orderAction";
import { useDispatch, useSelector } from "react-redux";
import MobileNavBottom from "../Footer/MobileNavBarBottom";
import { isMobile } from "react-device-detect";

export const OrderSummary = () => {
  const [searchParams] = useSearchParams();
  let root = useSelector((state) => state.rootReducer);
  let dispatch = useDispatch();

  let productFinal = root.orderDetails?.items;
  let [stateLoading, setStateLoading] = useState(true);
  let [paymentDone, setPaymentDone] = useState(false);

  let paymentType = "Dintero";

  let responseType = searchParams.get("responseType");
  let responseCode = searchParams.get("responseCode");
  let transaction_id = responseType
    ? searchParams.get("transactionId")
    : searchParams.get("transaction_id");
  let transactionCancelled = searchParams.get("error");

  let transactionObj = {};

  ///Check if response type is sale & response code is A01
  ///A01 = success
  if (responseType && responseCode == "A01") {
    let card_num = searchParams.get("accountNumberMasked");
    let expiry = searchParams.get("accountAccessory");

    paymentType = "TDIPay";

    transactionObj = {
      status: "AUTHORIZED",
      account_id: searchParams.get("accountId"),
      transaction_id: transaction_id,
      vendorCode: "47001", ///this should be dynamic
      vendorName: "TDIPay",
      card: {
        masked_pan: card_num,
        expiry_date: expiry,
        brand: "Card"
      }
    };
  }
  let vipps = searchParams.get("vipps");
  let order_id = searchParams.get("order_id");

  ///Check for Vipps
  if (order_id && vipps == "true") {
    console.log("L-16, inside tdvipps status-------------->");
    paymentType = "tdVipps";

    transactionObj = {
      status: "AUTHORIZED",
      branch_id: searchParams.get("branch_id"),
      order_id: searchParams.get("order_id"),
      vendorCode: searchParams.get("msn"),
      vendorName: "tdVipps"
    };
    console.log("L-63, transobj", transactionObj);
  }

  const onFinish = (values) => {};

  useEffect(() => {
    let timer = setTimeout(() => {
      if (root.orderId && transaction_id) {
        dispatch(
          checkOrderStatus(
            root.orderId,
            transaction_id,
            paymentType,
            transactionObj
          )
        )
          .then(() => {
            setStateLoading(false);
          })
          .catch(() => {
            setStateLoading(false);
          });
      } else if (!root.orderId && paymentType == "TDIPay" && transaction_id) {
        dispatch(
          checkOrderStatus("", transaction_id, paymentType, transactionObj)
        )
          .then(() => {
            setStateLoading(false);
          })
          .catch(() => {
            setStateLoading(false);
          });
      } else if (vipps && order_id) {
        dispatch(checkOrderStatus(order_id, "", "tdVipps", transactionObj))
          .then(() => {
            setStateLoading(false);
          })
          .catch(() => {
            setStateLoading(false);
          });
      } else if (root.orderId && transactionCancelled == "cancelled") {
        dispatch(refundCouponValue(root.orderId))
          .then(() => {
            setStateLoading(false);
          })
          .catch(() => {
            setStateLoading(false);
          });
      } else {
        order_id = searchParams.get("merchant_reference");

        if (order_id) {
          console.log(
            "---running timer else----",
            order_id,
            transaction_id,
            paymentType,
            transactionObj
          );

          dispatch(
            checkOrderStatus(
              order_id,
              transaction_id,
              paymentType,
              transactionObj
            )
          )
            .then(() => {
              setStateLoading(false);
            })
            .catch(() => {
              setStateLoading(false);
            });
        } else {
          setStateLoading(false);
        }
      }
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (root.orderOtp && root.paymentDone) {
      setPaymentDone(true);
    }
  }, [root.orderOtp, root.paymentDone]);

  const onFinishFailed = (errorInfo) => {};
  return (
    <>
      <div className="formWrapper" id="payment-status">
        <Row className="formRow mt-5">
          <Col span={24}>
            <div className="site-card-border-less-wrapper">
              <Card bordered={false} className="cardContainer">
                <div className="formLeft">
                  {stateLoading && (
                    <Row>
                      <Col
                        span={24}
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          color: "white"
                        }}
                      >
                        <h3>Laster Vennligst vent...</h3>
                      </Col>
                    </Row>
                  )}
                  {paymentDone && !stateLoading && (
                    <Row className="justify-content-start p-3 flex-column">
                      <div className="py-3 d-flex justify-content-start align-items-center">
                        {" "}
                        <AiOutlineCheckCircle
                          style={{
                            width: "50px",
                            height: "50px",
                            color: "#0C88D8",
                            marginRight: "10px"
                          }}
                        />
                        <h4 className="mb-0">Bestillingen ble lagt inn</h4>
                      </div>
                      <div>
                        <p>
                          <b>Din OTP: {root.orderOtp}</b>.
                        </p>
                        <p style={{ fontSize: "14px" }}>
                          <i>
                            Vi er glade for å bekrefte din nylige matbestilling
                            med EzyGo. Du vil motta ordrebekreftelsen via e-post
                            og SMS som inneholder OTP og angi denne OTP ved
                            mottak. IKKE del denne engangskoden med noen andre.
                          </i>
                        </p>
                        <>
                          <Descriptions
                            title="Bestillingsinformasjon"
                            className="mt-3"
                          ></Descriptions>{" "}
                          {productFinal.map((e) => {
                            return (
                              <Descriptions key={e._id}>
                                <Descriptions.Item label={e.name}>
                                  Quantity: {e.quantity}
                                </Descriptions.Item>
                              </Descriptions>
                            );
                          })}
                          <Divider />
                          <Descriptions>
                            <Descriptions.Item label="Totall">
                              <b> Kr. {root?.orderDetails?.totalPrice}</b>
                            </Descriptions.Item>
                          </Descriptions>
                        </>
                        <>
                          <Descriptions
                            title="Brukerinformasjon"
                            className="mt-3"
                          >
                            <Descriptions.Item label="Adresse">
                              {root.orderDetails.address.street}
                            </Descriptions.Item>
                          </Descriptions>
                        </>
                      </div>
                      <div className="py-3 goBackBtn">
                        <NavLink to="/" className="orderBtn">
                          Utforsk flere restauranter
                        </NavLink>
                      </div>
                    </Row>
                  )}

                  {!paymentDone && !stateLoading && (
                    <Row className="justify-content-start p-3 flex-column">
                      <div className="py-3 d-flex justify-content-start flex-column align-items-center">
                        {" "}
                        <AiOutlineClose
                          style={{
                            width: "100px",
                            height: "100px",
                            color: "red",
                            marginRight: "10px"
                          }}
                        />
                        <h4 className="text-white mb-0">
                          Å nei, bestillingen mislyktes
                        </h4>
                      </div>
                      <div>
                        <p style={{ fontSize: "14px" }} className="text-center">
                          <i>
                            Vi beklager å måtte informere deg om at det var et
                            problem med din nylige matbestilling gjennom Tree
                            Drive Navn på plattform/restaurant. Vi beklager evt
                            ulempe dette kan ha medført.
                          </i>
                        </p>
                      </div>
                      <div className="py-3 goBackBtn">
                        <NavLink to="/" className="orderBtn">
                          Utforsk flere restauranter
                        </NavLink>
                        <NavLink to="/checkout" className="orderBtn">
                          Tilbake til betalingssiden
                        </NavLink>
                      </div>
                    </Row>
                  )}
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
      {isMobile && <MobileNavBottom />}
    </>
  );
};
