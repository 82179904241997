import React, { useEffect, useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import { s3url, apiUrl } from "../../helper/endpoint";
import axios from "axios";

import MobileNavTop from "../Header/MobileTopNav";
import BottomFooter from "../Footer/BottomFooter";
import MobileNavBottom from "../Footer/MobileNavBarBottom";

import { Container, Button, Modal } from "react-bootstrap";
import { Row, Col, Form, Carousel, Card } from "antd";
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent
} from "@mui/material";
import tflCoinStill from "../../assets/coinphoto.jpg";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { rootAction } from "../../store/slices/rootSlices";
import AppDownload from "./AppDownload";
import bellBig from "../../assets/yellowbell.png";
import popupImg from "../../assets/popupmodal.jpg";
import GiftCard from "../../assets/tflimagecode.jpg";
import playStore from "../../assets/googleplay.png";
import posPhoto from "../../assets/pos.jpg";
import helloBot from "../../assets/hello.png";
import appStore from "../../assets/appstore.png";
import icon1 from "../../assets/icon11.png";
import icon2 from "../../assets/icon22.png";
import icon3 from "../../assets/icon33.png";
import icon4 from "../../assets/icon44.png";
import icon5 from "../../assets/icon55.png";
import bannerImg from "../../assets/banner2.png";
import ReserveTable from "../../assets/resTable.png.jpg";
import coins from "../../assets/coins.gif";
import coinsText from "../../assets/tfl coin text.png";

import AOS from "aos";
import "aos/dist/aos.css";

import { customerAction } from "../../store/slices/customerslices";
import { useLocation } from "react-router-dom";
import PopUpGiftCard from "../../containers/Modal/PopUpGiftCard";
import ScrollToTop from "../ScrollToTop";

export default function OnlineOrder() {
  let address = useSelector((state) => state.locationReducer);
  console.log("address", address);
  const [form] = Form.useForm();
  let user = useSelector((state) => state.userReducer);
  const [bannerTxt, setBannerTxt] = useState("Lyst på deilig mat??");
  const [gitfCardModalToBuy, setgiftCardModalToBuy] = useState(false);

  const [show, setShow] = useState(false);
  const [gitfCardModal, setgiftCardModal] = useState(false);

  const [advertisements, setAdvertisements] = useState([]);

  const onOpenGiftCardModal = () => {
    setgiftCardModal(true);
  };

  const onInfoBoxScanner = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const onOpenGiftCardModalToBuyToBuy = () => {
    setgiftCardModalToBuy(true);
  };

  useEffect(() => {
    localStorage.setItem("TFLCartData", []);
    localStorage.setItem("cartData", []);
    localStorage.setItem("vendorOrderType", []);
    localStorage.setItem("vid", []);
  });

  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);
  useEffect(() => {
    //headerTexts();
    dispatch(customerAction.removeCustomer());

    if (isMobile) {
      getHomePageAdv();
    }
  }, []);

  let dispatch = useDispatch();

  let navigate = useNavigate();

  const [expanded, setExpanded] = React.useState(false);

  const contentStyle = {
    margin: 0,
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    flexDirection: "column",
    padding: "20px"
  };
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [open, setOpen] = useState(true);

  const [displayPopUp, setDisplayPopUp] = useState(true);

  const closePopUp = () => {
    localStorage.setItem("seenPopUp", true);
    setDisplayPopUp(false);
  };

  useEffect(() => {
    let returningUser = localStorage.getItem("seenPopUp");
    setDisplayPopUp(!returningUser);
  }, []);

  const getHomePageAdv = async () => {
    console.log("---fetching advertisments for home page---");
    try {
      const response = await axios.get(
        `${apiUrl}/api/v1/treeDrive/restaurant/advertisementsNew`
        //payload
      );

      let homePageAds = response.data.data;
      setAdvertisements(homePageAds);
      console.log("--homepage advertisements---", homePageAds);
      // setResDataApi(resData);
    } catch (error) {
      console.error(error);
    }
  };

  const renderAd = (data) => {
    let adType = data.contentType == "video" ? "video" : "picture";
    let url = `${s3url}/${data.url}`;

    let ele = <img src={url} alt={adType} height={240} />;

    if (adType == "video") {
      ele = (
        <video
          controls
          width="100%"
          height="200"
          onEnded={handleVideoEnd}
          className=""
        >
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    return ele;
  };

  const carouselRef = useRef(null); // Reference to the Carousel component

  const handleVideoEnd = () => {
    // Advance to the next slide in the carousel
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  return (
    <>
      {displayPopUp && (
        <Dialog open={open} className="onloadModal">
          <IconButton
            aria-label="close"
            onClick={closePopUp}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          ></IconButton>
          <DialogContent
            dividers
            className="d-flex flex-column align-items-center modalBox"
          >
            <img
              src={coins}
              alt="coins"
              style={{ height: "150px" }}
              className="mt-2"
              loading="lazy"
            />
            <h4
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                paddingTop: "20px"
              }}
            >
              {" "}
              INTRODUSERER DIGITALT
            </h4>
            <img
              src={coinsText}
              alt="coins text"
              width={"150px"}
              loading="lazy"
            />
            <p align="center">Utforsk mer ved å besøke TFL-mynt seksjonen</p>
          </DialogContent>
          <DialogActions>
            <Button className="popModalBtn" autoFocus onClick={closePopUp}>
              Greit
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {isMobile && <MobileNavTop />}
      {isMobile && (
        <Container
          fluid
          id="online-header mobileView"
          className="px-0 py-5 my-2"
        >
          {user.token == "" ? (
            <Row className="px-4 pt-2">
              {" "}
              <i>
                {" "}
                For flere fordeler{" "}
                <u>
                  <NavLink to="/signup" className=" ">
                    <b>Registrer</b>
                  </NavLink>
                </u>{" "}
                deg eller{" "}
                <u>
                  <NavLink to="/login" className="">
                    <b>Logg inn</b>
                  </NavLink>
                </u>{" "}
                !!
              </i>
            </Row>
          ) : (
            <Row className="px-4 pt-2">
              Hallo&nbsp;
              <i>
                <b>
                  {user.firstName} {user.lastName},
                </b>
              </i>{" "}
            </Row>
          )}
          <Row justify="center" align="middle" style={{ maxHeight: "200px" }}>
            <Col span={12}></Col>
          </Row>
          <Row className="justify-content-evenly mt-3 smallCard">
            <Col
              span={10}
              className="smallCard1"
              onClick={() => {
                dispatch(rootAction.setOrderType("Drive Out"));
                navigate("/restaurants");
              }}
            /> <Col
            span={10}
            className="smallCard4"
            onClick={() => navigate("/profile")}
          />
          </Row>
          {/* <Row className="justify-content-evenly mt-3 smallCard">
            <Col span={10} className="smallCard3" />
           
            <Col
              span={10}
              className="smallCard2"
              onClick={onOpenGiftCardModal}
            />
          </Row> */}
          <Row className="justify-content-evenly mt-3 smallCard">
            <Col
              span={10}
              className="smallCard5"
              onClick={() => {
                dispatch(rootAction.setOrderType("reserveTable"));
                navigate("/restaurants");
              }}
            />{" "}
            <Col
              span={10}
              className="smallCard6"
              onClick={() => {
                navigate("/tflMarket");
              }}
            />
          </Row>

          {advertisements.length > 0 ? (
            <div className="px-4 pt-3">
              <Carousel
                effect="fade"
                autoplay={true}
                autoplaySpeed={5000}
                ref={carouselRef}
                dots={false}
                className="mt-4"
                //style={{ width: "100vw" }}
              >
                {advertisements.map((data, index) => {
                  return <div key={index}>{renderAd(data)}</div>;
                })}
              </Carousel>
            </div>
          ) : (
            ""
          )}

          <Carousel autoplay className="p-3">
            <div
              className="carousel1"
              onClick={() => {
                dispatch(rootAction.setOrderType("Drive Out"));
                navigate("/restaurants");
              }}
            >
              <div style={contentStyle}>
                <h3>Order the flavour of happiness</h3>
                <p>
                  {" "}
                  Ditt begjær og vår kommando! Bestill nå og nyt ekstraordinært
                  med bare et trykk unna!
                </p>
              </div>{" "}
            </div>
            {/* <div className="carousel3" onClick={onOpenGiftCardModalToBuyToBuy}>
              <div style={contentStyle}>
                {" "}
                <h3>Gave til dine kjære.</h3>
                <p>
                  Our Gift cards unlock a world of favour, bringing the joy of
                  delicious meal
                </p>
              </div>
            </div> */}
            <div
              className="carousel2"
              onClick={() => {
                dispatch(rootAction.setOrderType("Drive Out"));
                navigate("/restaurants");
              }}
            >
              <div style={contentStyle}>
                {" "}
                <h3>Plukke opp</h3>
                <p>Henting tilgjengelig fra restauranten!</p>
              </div>
            </div>
            <div
              className="carousel4"
              onClick={() => {
                dispatch(rootAction.setOrderType("reserveTable"));
                navigate("/restaurants");
              }}
            >
              <div style={contentStyle}>
                {" "}
                <h3>Reserver bord</h3>
                <p>Bestill fra setet ditt og vent på maten din!</p>
              </div>
            </div>
          </Carousel>
          <Row className="px-3 pt-2 pb-5 bottomThought mb-5">
            <Col span={24}>
              <img src={bellBig} alt="bellBig" loading="lazy" />
            </Col>
            <Col span={24} className="mt-3">
              <p>Mat er den ultimate terapien!</p>
            </Col>
          </Row>
        </Container>
      )}
      {isMobile && <MobileNavBottom />}
      {!isMobile && (
        <Container fluid id="online-header" className="p-0">
          <Row className="banner mt-5 p-5">
            <Col
              span={9}
              className="d-flex flex-column justify-content-center align-items-start fullWidthTab"
            >
              <h1 className="headerText">Finn oss i nærheten av deg.</h1>
              <p>
                <i style={{ paddingRight: "10px" }}>
                  "Tilfredsstill trangen din med hurtigmatlevering <br />
                  og oppdag en verden av praktiske tjenester til
                  fingerspissene."
                </i>
              </p>
              <mapbox-search-box
                access-token="<your access token here>"
                proximity="0,0"
              ></mapbox-search-box>
              <ul className="list-unstyled pt-4 pb-4 ">
                <li style={{ marginBottom: "10px" }}>
                  <a
                    className="d-flex alilgn-items-center"
                    onClick={() => {
                      dispatch(rootAction.setOrderType("Drive Out"));
                      navigate("/restaurants");
                    }}
                  >
                    <img src={icon1} alt="icon1" loading="lazy" />
                    <p className="d-flex flex-column align-items-start justify-content-center text-dark text-bold">
                      &nbsp;Online mat bestilling
                    </p>
                  </a>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <a
                    className="d-flex alilgn-items-center"
                    onClick={() => {
                      dispatch(rootAction.setOrderType("reserveTable"));
                      navigate("/restaurants");
                    }}
                  >
                    <img src={icon2} alt="icon2" loading="lazy" />
                    <p className="d-flex flex-column align-items-start justify-content-center text-dark text-bold">
                      &nbsp;Reserver bord
                    </p>
                  </a>
                </li>
                <li style={{ marginBottom: "10px" }}>
                  <a
                    onClick={onInfoBoxScanner}
                    className="d-flex align-items-center"
                  >
                    <img src={icon3} alt="icon3" loading="lazy" />
                    <p className="d-flex flex-column align-items-start justify-content-center text-dark text-bold">
                      &nbsp;Skann og bestill
                    </p>
                  </a>
                  {show && (
                    <Modal
                      show={show}
                      onHide={handleClose}
                      className="giftCardModal"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title style={{ color: "black" }}>
                          For problemfri bestilling
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Row className="mb-2">
                          <img
                            src={popupImg}
                            width={"100%"}
                            alt="popupImg"
                            loading="lazy"
                          />
                        </Row>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Greit
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}
                </li>
                {/* <li style={{ marginBottom: "10px" }}>
                  <a
                    onClick={onOpenGiftCardModal}
                    className="d-flex align-items-center"
                  >
                    <img src={icon4} alt="icon4" loading="lazy" />
                    <p className="d-flex flex-column align-items-start justify-content-center text-dark text-bold">
                      &nbsp;Gave et måltid med et TFL GAVEKORT
                    </p>
                  </a>
                </li> */}
                <li style={{ marginBottom: "10px" }}>
                  <a
                    className="d-flex align-items-center"
                    onClick={() => navigate("/profile")}
                  >
                    <img
                      src={icon5}
                      alt="icon5"
                      loading="lazy"
                      style={{ height: "fit-content" }}
                    />
                    <p className="d-flex flex-column align-items-start  justify-content-center text-dark text-bold">
                      &nbsp;Bli med i lojalitetsprogrammet og tjen TFL-mynter på
                      hver bestilling
                    </p>
                  </a>
                </li>
              </ul>
              <div className="d-flex align-items-center pt-4 storeImg">
                <a
                  href="https://apps.apple.com/in/app/tree-drive/id6444897318"
                  target="_blank"
                >
                  <img
                    src={appStore}
                    width="150px"
                    alt="appStore"
                    loading="lazy"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.omvadata.treedriveuser"
                  target="_blank"
                >
                  <img
                    src={playStore}
                    width="150px"
                    alt="playStore"
                    loading="lazy"
                  />
                </a>
              </div>
            </Col>
            <Col span={15} className="d-flex align-items-center bannerSideImg">
              <img
                src={bannerImg}
                width={"100%"}
                alt="banner-img"
                loading="lazy"
              />
            </Col>
          </Row>
          <Row className="p-5 my-5 justify-content-between" id="reserve">
            <div
              data-aos="fade-right"
              data-aos-duration="8000"
              className="col-sm-8 col-md-7 d-flex flex-column justify-content-center"
              style={{ paddingRight: "20px" }}
            >
              <h2>
                UANSTRENGT{" "}
                <span>
                  BORD
                  <br />
                  BESTILLINGER{" "}
                </span>
                HOS OSS
              </h2>
              <p>
                Opplev den ultimate bekvemmeligheten av å reservere bord gjennom
                vår dedikerte app. Med bare noen få trykk kan du sikre deg plass
                og nyte en fantastisk matopplevelse.
              </p>
              <br />
              <p>Hvorfor velge vår app for bordbestilling?</p> <br />
              <ul>
                <li>
                  <strong>Enkel å bruke:</strong> Appen vår gir et brukervennlig
                  grensesnitt som gjør bordbestilling raskt og enkelt.
                </li>
                <li>
                  <strong>Øyeblikkelig bekreftelse:</strong> Motta umiddelbar
                  bekreftelse på reservasjonen din, noe som gir deg trygghet.
                </li>
                <li>
                  <strong>Tilpass bare alternativer: </strong>Spesifiser din
                  foretrukne dato, klokkeslett og antall gjester for å
                  skreddersy din matopplevelse.
                </li>
                <li>
                  <strong>Sanntids-tilgjengelighet: </strong> Få
                  sanntids-oppdateringer om bordtilgjengelighet, og sikrer at du
                  finner det perfekte tidspunktet for besøket ditt.
                </li>
              </ul>{" "}
              <br />
              <p>Klar til å reservere bord?</p> <br />
              <a
                className="read"
                onClick={() => {
                  dispatch(rootAction.setOrderType("reserveTable"));
                  navigate("/restaurants");
                }}
              >
                Reserver bord
              </a>
              <br />
              <i>
                Sulten nå, dietten starter i morgen.: &nbsp;
                <strong>
                  <a
                    onClick={() => {
                      navigate("/restaurants");
                    }}
                  >
                    <u>Finn restauranter i nærheten</u>
                  </a>
                </strong>
              </i>
            </div>
            <div
              className="col-sm-3 col-md-5 aboutUs justify-content-end d-flex"
              data-aos="fade-left"
              data-aos-duration="8000"
            >
              <img
                src={ReserveTable}
                width="600px"
                alt="ReserveTable"
                loading="lazy"
              />
            </div>
          </Row>
          <Row
            className=" p-5 my-5 justify-content-between my-5"
            id="tflGiftCard"
          >
            <Col
              xs={5}
              md={8}
              className="slider d-flex justify-content-center align-items-center"
              data-aos="fade-right"
              data-aos-duration="8000"
            >
              <img
                src={GiftCard}
                className="tflHeader"
                alt="GiftCard"
                loading="lazy"
                width="100%"
                style={{ borderRadius: "15px" }}
              />
            </Col>
            <Col
              xs={5}
              md={14}
              data-aos="fade-left"
              data-aos-duration="8000"
              className="about-text d-flex flex-column justify-content-center"
            >
              <h2 className="headerText">
                VI INTRODUSERER <span>TFL</span> GIFT CARD
              </h2>
              <p>
                Din inngangsport til kulinariske herligheter! Del matgleden med
                dine kjære, og gi dem friheten til å smake på favorittrettene
                eller produktene deres uten problemer. Sømløst innløselig på
                deltakende virksomheter, det er den perfekte gaven for enhver
                anledning.
              </p>
              <ul>
                <li>
                  <b>Styrk barna dine</b>: De vil bruke ansvarlige penger med
                  TFL-kort, og sikre at midler eksklusivt bevilges til matkjøp.
                </li>
                <li>
                  <b>Digital bekvemmelighet</b>: TFL-gavekortet er en digital
                  løsning som lar mottakere nyte måltider eller handle på ulike
                  etablissementer i nettverket vårt.
                </li>
                <li>
                  {" "}
                  <b>Valgfri gave</b>: Tilby dine kjære friheten til å velge
                  favorittretter eller -produkter, noe som gjør enhver anledning
                  spesiell.
                </li>
                <li>
                  {" "}
                  <b>Sømløs innløsning</b>: Mottakere kan enkelt løse inn det
                  digitale kortet ved kjøpet, enten de spiser på middag eller
                  handler på nettet, for å sikre en problemfri opplevelse.
                </li>
                <li>
                  {" "}
                  <b>Fleksibilitet med TFL-mynter</b>: Dine opptjente
                  TFL-digitale mynter kan enkelt konverteres til TFL-digitale
                  kort, noe som gir et ekstra lag med bekvemmelighet og
                  allsidighet.
                </li>
                <li>
                  {" "}
                  <b>Spre lykke</b>: Hos EzyGo tror vi på matens kraft for å
                  bringe mennesker sammen, og TFL-gavekortet legemliggjør vår
                  forpliktelse til å spre lykke gjennom kulinariske opplevelser.
                </li>
              </ul>
              <br />
              {/* <div className="d-flex align-items-center">
                <button
                  className="read"
                  style={{
                    width: "200px"
                  }}
                  onClick={onOpenGiftCardModal}
                >
                  Kjøp nå
                </button>
                <button
                  className="simple-btn"
                  style={{
                    marginLeft: "20px"
                  }}
                  onClick={onOpenGiftCardModal}
                >
                  Se mer
                </button>
              </div> */}
              <br />
              <p>
                <i>
                  Smaksløkene skriker etter tilfredsstillelse:&nbsp;
                  <strong>
                    <a
                      onClick={() => {
                        navigate("/restaurants");
                      }}
                    >
                      {" "}
                      <u>Finn restauranter i nærheten</u>
                    </a>
                  </strong>
                </i>
              </p>
            </Col>
          </Row>
          <Row
            className=" p-5 my-5 justify-content-between my-5"
            id="tflGiftCard"
          >
            <Col
              xs={5}
              md={14}
              data-aos="fade-right"
              data-aos-duration="8000"
              className="about-text d-flex flex-column justify-content-center"
            >
              <h2 className="headerText">
                <span>TFL</span> Coins
              </h2>
              <p>
                {" "}
                TFL Coins" står som et omfattende lojalitetsprogram, som tilbyr
                ulike muligheter for kunder til å tjene TFL digitale mynter og
                løse inn belønninger, noe som øker allsidigheten og verdien. Den
                unike egenskapen ved å konvertere disse digitale myntene til
                selskapets aksjer stimulerer ikke bare lojalitet, men dyrker
                også en følelse av eierskap og investering i selskapets bane
                Denne dypere forbindelsen mellom kunder og merkevaren vår
                fremmer varig engasjement og lojalitet, og styrker båndet vårt
                på lang sikt.
              </p>
              <br />
              <ul>
                <li>
                  <b>Lojalitetsprogram</b>: EzyGo Coins er vårt
                  lojalitetsprogram designet for å belønne kunder for deres
                  engasjement og kjøpe.
                </li>
                <li>
                  <b>Engagement Coin Collection</b> refererer til prosessen med
                  å tjene Tree For Life-mynter ved å delta aktivt i ulike
                  aktiviteter eller engasjementer innenfor plattformen.
                </li>
                <li>
                  <b>Valutakonvertering TFL Coins</b> tilbyr fleksibiliteten til
                  å konvertere belønninger til enten gavekort eller
                  bedriftsandeler, og fremmer dypere kundeengasjement og
                  lojalitet. Samle flere mynter for økte fordeler og oppmuntre
                  til fortsatt deltakelse i programmet.
                </li>
                <li>
                  <b>Innløsning av gavekort TFL Gift Cards</b> gir allsidige
                  innløsningsalternativer, som lar kunder bestille mat på
                  nettet, handle direkte med medlemmer og kjøpe produkter fra
                  EzyGo-markedsplassen, noe som øker bekvemmeligheten og verdien
                  for lojale kunder.
                </li>
              </ul>
              <a onClick={() => navigate("/profile")}>
                <u>
                  <b>
                    Få deg Registrer deg hos oss for et lojalitetsprogram - LOGG
                    INN/ REGISTRER DEG
                  </b>
                </u>
              </a>
            </Col>
            <Col
              xs={5}
              md={8}
              className="slider d-flex justify-content-center align-items-center"
              data-aos="fade-left"
              data-aos-duration="8000"
            >
              <img
                alt="tflCoinStill"
                loading="lazy"
                src={tflCoinStill}
                width={"100%"}
                style={{ borderRadius: "15px" }}
              />
            </Col>
          </Row>
          {/* <AppDownload /> */}
          <Row
            className=" p-5 my-5 justify-content-between my-5 allInOneSec"
            id="tflGiftCard"
          >
            <Col
              xs={5}
              md={10}
              className="slider col-img d-flex align-items-center  position-relative"
              data-aos="fade-right"
              data-aos-duration="8000"
            >
              <img
                src={posPhoto}
                className="posPhoto"
                alt="posPhoto"
                loading="lazy"
              />
              <img
                src={helloBot}
                className="position-absolute helloBot"
                width="200px"
                alt="helloBot"
                loading="lazy"
              />
            </Col>
            <Col
              xs={5}
              md={13}
              data-aos="fade-left"
              data-aos-duration="8000"
              className="about-text d-flex flex-column justify-content-center"
            >
              <h2 className="headerText">
                VIRKSOMHETSEIERE: FORANDRE DIN <br />
                RESTAURANT OG DETALJHANDEL MED VÅR <br />
                <span>ALT-I-ETT-PLATTFORM</span>
              </h2>
              <p className="headerparagraph pt-3">
                Revolusjoner din restaurantopplevelse ved å bli med i
                plattformen vår, der vi ikke bare effektiviserer matbestilling
                for kundene dine, men også åpner døren til en rekke
                tilleggstjenester, hver skreddersydd for å forbedre virksomheten
                din
              </p>
              <ul>
                <li>
                  <b>Strømlinjeformet matbestilling</b>: Plattformen forenkler
                  og forbedrer matbestillingsprosessen for kundene, og sikrer en
                  jevn og effektiv opplevelse. Funksjoner kan inkludere
                  brukervennlige grensesnitt, raske bestillingsalternativer og
                  sømløs betalingsbehandling.
                </li>
                <li>
                  <b>Omfattende forretningstjenester</b>: Utover matbestilling
                  tilbyr plattformen et bredt spekter av tilleggstjenester
                  skreddersydd for å forbedre restaurant og
                  detalj-handelsbedrifter. Disse tjenestene kan omfatte
                  markedsføringsløsninger, kundeanalyse,
                  forsyningskjede-optimalisering og mer.
                </li>
                <li>
                  <b>Skreddersydd for bedriftsvekst</b>: Plattformen er designet
                  med tanke på veksten av restaurant- og
                  detalj-handelsbedrifter. Det gir verktøy og ressurser for å
                  hjelpe bedrifter med å utvide kundebasen, øke inntektene og
                  optimalisere driften.
                </li>
                <li>
                  <b>Mulighet for suksess</b>: Ved å bli med i plattformen får
                  bedrifter ikke bare tilgang til strømlinjeformet
                  matbestilling, men låser også opp potensialet for akselerert
                  suksess. Plattformens omfattende pakke med tjenester tilbyr
                  verktøyene som trengs for å trives i dagens konkurranseutsatte
                  marked.
                </li>
                <li>
                  <b>Hastighet og effektivitet</b>: Legg vekt på hastigheten og
                  effektiviteten til matlevering tilrettelagt av plattformen.
                  Bedrifter kan levere bestillinger til kunder raskt og
                  pålitelig, noe som øker den generelle kundetilfredsheten.
                </li>
                <li>
                  {" "}
                  <b>Akselerert forretningsvekst</b>: Fremhev plattformens evne
                  til å drive forretningsvekst i et akselerert tempo. Gjennom
                  tilgang til verdifulle ressurser og tjenester kan bedrifter nå
                  sine vekstmål raskere. Men det er bare begynnelsen på det vi
                  tilbyr. Ta spranget i dag, og du vil låse opp mer enn bare
                  bestillingssystemet. Plattformen vår er designet for å tilby
                  et omfattende utvalg av forretningstjenester som vil gi vekst.
                  Besøk ezygo.no for å utforske mer om tjenestene våre.
                </li>
              </ul>
              <a
                href="https://ezygo.no/"
                className="read  mt-4"
                target="_blank"
              >
                Vite mer
              </a>
            </Col>
          </Row>
          <Row className="p-5 mt-5 justify-content-between" id="lowerImgBlock">
            <div
              data-aos="fade"
              data-aos-duration="8000"
              className="col about-text d-flex flex-column align-items-center justify-content-center text-white"
            >
              <h4 className="text-white">Oppfyll dine kulinariske ønsker</h4>
              <p className="text-center mt-3 text-white">
                Bestill enkelt deilig mat eller reserver bord for en
                uforglemmelig matopplevelse. Dine kulinariske ønsker, din måte.
              </p>
              <i>
                <strong>Eksklusivt tilbud:</strong> Bestill nå og nyt spesial
                tilbud!
              </i>
              <ul className="list-unstyled d-flex overflow-hidden mt-3">
                <li>
                  <a
                    onClick={() => {
                      dispatch(rootAction.setOrderType("Drive Out"));
                      navigate("/restaurants");
                    }}
                    className="simple-btn"
                  >
                    BESTILL PÅ NETT
                  </a>
                </li>
                <li>
                  <a
                    className="read"
                    onClick={() => {
                      dispatch(rootAction.setOrderType("reserveTable"));
                      navigate("/restaurants");
                    }}
                  >
                    Reserver bord
                  </a>
                </li>{" "}
                {/* <li>
                  <a
                    onClick={onOpenGiftCardModal}
                    style={{ marginLeft: 10 }}
                    className="simple-btn"
                  >
                    Kjøp gavekort
                  </a>
                </li> */}
              </ul>
            </div>
          </Row>
        </Container>
      )}
      {!isMobile && <BottomFooter />}

      {gitfCardModal && <PopUpGiftCard />}

      <ScrollToTop />
    </>
  );
}
