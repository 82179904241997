// export const apiUrl = "https://api.a-board.tech";
// export const apiUrl = "http://localhost:8080";
export const apiUrl = "https://api.a-board.world";

export const s3url = "https://pos-stockholm-bucket.s3.eu-north-1.amazonaws.com";
export const tDriveEndpoint = "/api/v1/treeDrive/";
export const tDriveEndpointPublic = "/api/v1/public/";

// export const reserveTableurl =
//   "https://web.a-board.tech/restaurant/reservetable/";

export const reserveTableurl =
  "https://web.a-board.world/restaurant/reservetable/";
