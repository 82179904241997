import React, { useEffect, useState, useMemo } from "react";
import { Row, Col, Card, Button, Badge, Modal, Alert } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
import axios from "axios";
import history from "../../history";
import { s3url, apiUrl } from "../../helper/endpoint";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import MobileNavBottom from "../Footer/MobileNavBarBottom";
import smallCoin from "../../assets/SMALLCOIN.png";
import { NavLink } from "react-router-dom";
import comingSoon from "../../assets/comingSoon.jpg";
const TflMarket = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [marketData, setMarketData] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [deliveryCharges, setDeliveryCharges] = useState([]);
  const [cartItemCount, setCartItemCount] = useState(0);
  const navigate = useNavigate();
  const user = useSelector((state) => state.userReducer);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/treeDrive/appuser/getAllTFLProducts`
        );
        setMarketData(response.data.data);
        localStorage.setItem("pointsValue", response.data.pointsValue);
        setDeliveryCharges(response.data.deliveryCharges);
      } catch (error) {
        console.error("Error fetching market data:", error);
      }
    };
    fetchData();
  }, []);

  const totalAmount = useMemo(() => {
    return cartData.reduce((acc, item) => acc + item.amount * item.quantity, 0);
  }, [cartData]);

  const updateCart = (productInfo, productAction, id) => {
    setCartData((prevCartData) => {
      let totalLength = 0; // Initialize total length
      let totalBreadth = 0; // Initialize total breadth
      let totalHeight = 0; // Initialize total height

      const existingItemIndex = prevCartData.findIndex(
        (item) => item._id === (productInfo ? productInfo._id : id)
      );

      const updatedCart = prevCartData
        .map((item, index) => {
          if (index === existingItemIndex) {
            let newQuantity = item.quantity;
            if (productAction === "add") {
              if (item.quantity < item.availableQuantity) {
                newQuantity += 1;
              } else {
                alert(`Only ${item.availableQuantity} available in stocks.`);
                newQuantity = item.availableQuantity;
              }
            } else if (productAction === "remove") {
              newQuantity -= 1;
            }

            if (newQuantity <= 0) {
              return null;
            }

            totalLength += item.dimensions.length * item.quantity; // Add item length to total length
            totalBreadth += item.dimensions.breadth * item.quantity; // Add item length to total breadth
            totalHeight += item.dimensions.height * item.quantity; // Add item length to total height

            return {
              ...item,
              quantity: newQuantity,
              totalAmount: item.amount * newQuantity,
              weight: item.weight * newQuantity,
              length: item.dimensions.length,
              breadth: item.dimensions.breadth,
              height: item.dimensions.height
            };
          }

          return item;
        })
        .filter((item) => item !== null);

      // If the item is new and being added to the cart
      if (existingItemIndex === -1 && productInfo) {
        const newItem = {
          ...productInfo,
          totalAmount: productInfo.amount,
          quantity: 1,
          weight: productInfo.weight,
          length: productInfo.dimensions.length,
          breadth: productInfo.dimensions.breadth,
          height: productInfo.dimensions.height
        };

        return [...updatedCart, newItem];
      }
      return updatedCart;
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
    localStorage.setItem("TFLCartData", JSON.stringify(cartData));
    const totalLength = cartData.reduce(
      (acc, item) => acc + item.dimensions.length * item.quantity,
      0
    );
    const totalBreadth = cartData.reduce(
      (acc, item) => acc + item.dimensions.breadth * item.quantity,
      0
    );
    const totalHeight = cartData.reduce(
      (acc, item) => acc + item.dimensions.height * item.quantity,
      0
    );
    navigate("/tflMarketCart", {
      state: {
        cartData,
        deliveryCharges,
        totalAmount,
        totalWeight: cartData.reduce((acc, item) => acc + item.weight, 0),
        totalLength,
        totalBreadth,
        totalHeight
      }
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const toggleExpand = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const truncate = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  useEffect(() => {
    const backCartData = localStorage.getItem("TFLCartData");
    if (backCartData) {
      setCartData(JSON.parse(backCartData));
    }
  }, []);

  useEffect(() => {
    const count = cartData.reduce((acc, item) => acc + item.quantity, 0);
    setCartItemCount(count);
  }, [cartData]);
  return (
    <>
      {isMobile && (
        <>
          {" "}
          {marketData != 0 ? (
            <Row className="my-5 py-5" id="tflMarketRow">
              <Col
                span={24}
                className="px-4 py-2 position-fixed w-100 bg-white"
                style={{ textAlign: "right", zIndex: 999, top: 0 }}
              >
                <Button onClick={showModal}>
                  <Badge count={cartItemCount}>
                    Cart &nbsp;
                    <ShoppingCartOutlined />
                  </Badge>
                </Button>
              </Col>
              <Col span={24} className="p-3">
                {marketData.map((data) => {
                  const isExpanded = expanded[data._id];
                  return (
                    <Card
                      key={data._id}
                      className="mb-3"
                      cover={
                        <img
                          className="m-3"
                          style={{ width: "100px" }}
                          alt="Product"
                          src={`${s3url}/${data.url}`}
                        />
                      }
                      actions={[
                        data.availableQuantity > 0 ? (
                          user.token ? (
                            <Button
                              className="w-100 border-0"
                              onClick={() => updateCart(data, "add")}
                            >
                              Legg i handlekurv
                            </Button>
                          ) : (
                            <NavLink to="/login" className="py-1">
                              Legg til
                            </NavLink>
                          )
                        ) : (
                          <Button disabled className="w-100 border-0">
                            Solgt
                          </Button>
                        )
                      ]}
                    >
                      <h5 className="text-center">
                        <b>{data.productName}</b>
                      </h5>
                      <div className="d-flex justify-content-between mb-1">
                        <p>
                          <b>Beløp</b>: {data.amount} Kr.
                        </p>
                        <p>
                          <b>Coins</b>: {data.RequiredPts}{" "}
                          <img src={smallCoin} alt="Coin" />
                        </p>
                      </div>
                      <p>
                        <b>Beskrivelse: </b>
                        {isExpanded
                          ? data.description
                          : truncate(data.description, 90)}
                        <Button
                          type="link"
                          onClick={() => toggleExpand(data._id)}
                          className="truncateClass"
                        >
                          {isExpanded ? "Hide" : "Show"}
                        </Button>
                      </p>
                    </Card>
                  );
                })}
              </Col>
            </Row>
          ) : (
            <Row
              className="my-5 py-5 d-flex justify-content-center align-items-center h-100"
              id="tflMarketRow"
            >
              <img src={comingSoon} />
            </Row>
          )}
        </>
      )}
      {isMobile && <MobileNavBottom />}
      <Modal
        title="Cart"
        visible={isModalOpen}
        onCancel={handleCancel}
        className="tflCartModal"
      >
        <p className="mb-4">
          Handlekurven din har totalt <b>{cartItemCount}</b> varer
          {cartItemCount !== 1 ? "s" : ""}.
        </p>
        {totalAmount != 0 ? (
          <>
            <div className="tflCartHeight">
              {cartData.map((item) => (
                <Row
                  key={item._id}
                  className="justify-content-between border p-3 mb-2"
                >
                  <Col
                    span={6}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <img
                      src={`${s3url}/${item.url}`}
                      style={{ width: "100%" }}
                      alt="Product"
                    />
                  </Col>
                  <Col span={16}>
                    <p>
                      <strong>{item.productName}</strong> &nbsp;*{item.quantity}
                    </p>
                    <div className="increBtn tflCart py-1 justify-content-start">
                      <span
                        className="minus"
                        onClick={() => updateCart(null, "remove", item._id)}
                      >
                        -
                      </span>
                      <span className="px-3">{item.quantity}</span>
                      {item.availableQuantity === item.quantity ? (
                        <span className="plus disabledBtn">+</span>
                      ) : (
                        <span
                          className="plus"
                          onClick={() => updateCart(null, "add", item._id)}
                        >
                          +
                        </span>
                      )}
                    </div>
                    <p className="mb-2">
                      Amount:&nbsp;
                      <b>{item.amount * item.quantity} Kr. </b>
                    </p>

                    <Alert
                      className="alertStyle"
                      type="warning"
                      showIcon
                      message={
                        <p>Bare skynd deg {item.availableQuantity} igjen!</p>
                      }
                    />
                  </Col>
                </Row>
              ))}
            </div>
            <hr />
            <Col span={24} className="p-3">
              <div className="d-flex justify-content-between">
                <p>Total</p>
                {totalAmount} Kr.
              </div>
            </Col>
            <Button
              key="ok"
              type="primary"
              onClick={handleOk}
              className="w-100 saveBtn"
            >
              Proceed
            </Button>{" "}
          </>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};

export default TflMarket;
