import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { isMobile } from "react-device-detect";
import MobileNavTop from "../Header/MobileTopNav";
import BottomFooter from "../Footer/BottomFooter";
import AppDownload from "./AppDownload";
import MobileNavBottom from "../Footer/MobileNavBarBottom";
import { s3url, apiUrl } from "../../helper/endpoint";

import { Container, Button, Modal } from "react-bootstrap";

import { Row, Col, Layout, Carousel, Typography, Card } from "antd";

import ScrollToTop from "../ScrollToTop";

import { rootAction } from "../../store/slices/rootSlices";

import tflMArket from "../../assets/5.jpg";
import grocerry from "../../assets/6.jpg";
import tflCoinStill from "../../assets/7.jpg";
import BookSalon from "../../assets/4.jpg";
import PopUpGiftCard from "../../containers/Modal/PopUpGiftCard";
import GiftCard from "../../assets/10.jpg";
import ReserveTable from "../../assets/1.jpg";
import bellBig from "../../assets/yellowbell.png";

const { Header, Footer, Content } = Layout;
const { Title, Paragraph } = Typography;

function App() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const carouselRef = useRef(null);
  let user = useSelector((state) => state.userReducer);

  const [giftCardModal, setgiftCardModal] = useState(false);
  const [advertisements, setAdvertisements] = useState([]);
  const [gitfCardModalToBuy, setgiftCardModalToBuy] = useState(false);

  const onOpenGiftCardModal = () => {
    setgiftCardModal(true);
  };
  const renderAd = (data) => {
    let adType = data.contentType == "video" ? "video" : "picture";
    let url = `${s3url}/${data.url}`;

    let ele = <img src={url} alt={adType} height={240} />;

    if (adType == "video") {
      ele = (
        <video
          controls
          width="100%"
          height="200"
          onEnded={handleVideoEnd}
          className=""
        >
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    return ele;
  };
  const handleVideoEnd = () => {
    // Advance to the next slide in the carousel
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };
  const contentStyle = {
    margin: 0,
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    flexDirection: "column",
    padding: "20px"
  };
  const onOpenGiftCardModalToBuyToBuy = () => {
    setgiftCardModalToBuy(true);
  };

  const contentCarouselStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "220px",
    color: "#fff",
    padding: "20px",
    textAlign: "center"
  };

  const carouselItems = [
    {
      id: 1,
      className: "carousel1",
      title: "Order the Flavour of Happiness",
      description:
        "Ditt begjær og vår kommando! Bestill nå og nyt ekstraordinært med bare et trykk unna!",
      onClick: () => {
        dispatch(rootAction.setOrderType("Drive Out"));
        navigate("/restaurants");
      }
    },
    // {
    //   id: 2,
    //   className: "carousel3",
    //   title: "Gave til dine kjære",
    //   description:
    //     "Our Gift cards unlock a world of favour, bringing the joy of a delicious meal.",
    //   onClick: () => {
    //     // Function to handle Gift Card modal
    //     onOpenGiftCardModalToBuyToBuy();
    //   }
    // },
    {
      id: 3,
      className: "carousel2",
      title: "Plukke opp",
      description: "Henting tilgjengelig fra restauranten!",
      onClick: () => {
        dispatch(rootAction.setOrderType("Drive Out"));
        navigate("/restaurants");
      }
    },
    {
      id: 4,
      className: "carousel4",
      title: "Reserver bord",
      description: "Bestill fra setet ditt og vent på maten din!",
      onClick: () => {
        dispatch(rootAction.setOrderType("reserveTable"));
        navigate("/restaurants");
      }
    }
  ];

  return (
    <Layout>
      {!isMobile && (
        <>
          {" "}
          <Header className="bannerImg ">
            <h2 className="textWhite font65px text-center text-uppercase sansita-black-italic">
              Finn oss i
              <br /> nærheten
            </h2>
            {/* <Title level={1} style={{ color: "white" }}></Title> */}
            <Button
              className="bannerBtn"
              size="large"
              style={{ marginTop: "20px" }}
              onClick={() => {
                navigate("/restaurants");
              }}
            >
              Order Now
            </Button>
          </Header>
          <Content>
            <Row
              className="my-5 justify-content-between padding-40-180"
              id="reserve"
            >
              <Col
                xs={5}
                md={12}
                data-aos="fade-right"
                data-aos-duration="8000"
                className="col-sm-8 col-md-7 d-flex flex-column justify-content-center"
              >
                <h2>
                  UANSTRENGT{" "}
                  <span>
                    BORD
                    <br />
                    BESTILLINGER{" "}
                  </span>
                  HOS OSS
                </h2>
                <p>
                  Nyt et brukervennlig grensesnitt for raske bestillinger,
                  umiddelbar bekreftelse for trygghet og sanntidsoppdateringer
                  om bordtilgjengelighet. Tilpass matopplevelsen din ved å velge
                  ønsket dato, klokkeslett og antall gjester – alt enkelt!
                </p>
                <br />
                <p>Klar til å reservere bord?</p> <br />
                <a
                  className="read"
                  onClick={() => {
                    dispatch(rootAction.setOrderType("reserveTable"));
                    navigate("/restaurants");
                  }}
                >
                  Reserver bord
                </a>
                <br />
                <i>
                  Sulten nå, dietten starter i morgen.: &nbsp;
                  <strong>
                    <a
                      onClick={() => {
                        navigate("/restaurants");
                      }}
                    >
                      <u>Finn restauranter i nærheten</u>
                    </a>
                  </strong>
                </i>
              </Col>
              <Col
                xs={5}
                md={10}
                className="col-sm-3 col-md-5 aboutUs justify-content-end d-flex"
                data-aos="fade-left"
                data-aos-duration="8000"
              >
                <img
                  src={ReserveTable}
                  width="600px"
                  alt="ReserveTable"
                  loading="lazy"
                />
              </Col>
            </Row>
            <Row
              className=" my-5 justify-content-between my-5 padding-40-180"
              id="tflGiftCard"
            >
              <Col
                xs={5}
                md={10}
                className="slider d-flex justify-content-center align-items-center"
                data-aos="fade-right"
                data-aos-duration="8000"
              >
                <img
                  src={GiftCard}
                  className="tflHeader"
                  alt="GiftCard"
                  loading="lazy"
                  width="100%"
                  style={{ borderRadius: "15px" }}
                />
              </Col>
              <Col
                xs={5}
                md={12}
                data-aos="fade-left"
                data-aos-duration="8000"
                className="about-text d-flex flex-column justify-content-center"
              >
                <h2 className="headerText">
                  VI INTRODUSERER <br />
                  <span>TFL</span>
                  <span> GIFT</span> CARD
                </h2>
                <p>
                  Del gleden med TFL-gavekortet – den perfekte gaven for enhver
                  anledning:
                </p>
                <ul>
                  <li>
                    <b>Frihet til å velge</b>: La dine kjære nyte favorittretter
                    eller produkter.
                  </li>
                  <li>
                    <b>Digital bekvemmelighet</b>: Enkelt innløselig på
                    deltakende virksomheter, både online og fysisk.
                  </li>
                  <li>
                    {" "}
                    <b>Ansvarlig bruk</b>: Perfekt for barn med midler
                    eksklusivt til matkjøp.
                  </li>
                  <li>
                    {" "}
                    <b>Fleksibilitet</b>: Konverter TFL-mynter til gavekort for
                    ekstra allsidighet.
                  </li>
                </ul>
                <br />
                {/* <div className="d-flex align-items-center">
                  <button
                    className="read"
                    style={{
                      width: "200px"
                    }}
                    onClick={onOpenGiftCardModal}
                  >
                    Kjøp nå
                  </button>
                  <button
                    className="simple-btn"
                    style={{
                      marginLeft: "20px"
                    }}
                    onClick={onOpenGiftCardModal}
                  >
                    Se mer
                  </button>
                </div> */}
                <br />
                <p>
                  <i>
                    Smaksløkene skriker etter tilfredsstillelse:&nbsp;
                    <strong>
                      <a
                        onClick={() => {
                          navigate("/restaurants");
                        }}
                      >
                        {" "}
                        <u>Finn restauranter i nærheten</u>
                      </a>
                    </strong>
                  </i>
                </p>
              </Col>
            </Row>
            <AppDownload />
            <Row
              className=" my-5 justify-content-between my-5 padding-40-180"
              id="tflGiftCard"
            >
              <Col
                xs={5}
                md={10}
                className="slider d-flex justify-content-center align-items-center"
                data-aos="fade-right"
                data-aos-duration="8000"
              >
                <img
                  alt="tflCoinStill"
                  loading="lazy"
                  src={tflCoinStill}
                  width={"100%"}
                  style={{ borderRadius: "15px" }}
                />
              </Col>
              <Col
                xs={5}
                md={12}
                data-aos="fade-left"
                data-aos-duration="8000"
                className="about-text d-flex flex-column justify-content-center"
              >
                <h2 className="headerText">
                  <span>TFL</span> COINS
                </h2>
                <p>
                  <b>TFL Coins</b> er et innovativt lojalitetsprogram som lar
                  kunder tjene digitale mynter gjennom engasjement og kjøp.
                  <br />
                  <br />
                  Myntene kan brukes til gavekort eller konverteres til aksjer,
                  noe som styrker lojalitet og investering i selskapet. Gavekort
                  kan brukes til matbestilling, shopping og kjøp på
                  EzyGo-markedsplassen, noe som gir fleksibilitet og verdi for
                  kundene.
                </p>
                <br />
                <a onClick={() => navigate("/profile")}>
                  <u>
                    <b>
                      Få deg Registrer deg hos oss for et lojalitetsprogram -
                      LOGG INN/ REGISTRER DEG
                    </b>
                  </u>
                </a>
              </Col>
            </Row>
            {/* <Row
              className="my-5 justify-content-between padding-40-180"
              id="reserve"
            >
              <div
                data-aos="fade-right"
                data-aos-duration="8000"
                className="col-sm-8 col-md-7 d-flex flex-column justify-content-center"
                style={{ paddingRight: "20px" }}
              >
                <h2>
                  BOOK
                  <span> SALON</span>
                </h2>
                <p>Dive into stories that inspire, inform, and entertain.</p>
                <ul>
                  <li>
                    Explore Diverse Genres: Fiction, self-help, environmental
                    books, and more.
                  </li>
                  <li>
                    Community Picks: See what others are reading and share your
                    recommendations.
                  </li>
                  <li>
                    {" "}
                    Book Swaps and Clubs: Join a club or exchange books with
                    like-minded readers.
                  </li>
                </ul>
                <br />
                <p>Your Personal Beauty Booking Hub</p> <br />
                <br />
                <i>
                  <strong>
                    <u>Coming Soon...</u>
                  </strong>
                </i>
              </div>
              <div
                className="col-sm-3 col-md-5 aboutUs justify-content-end d-flex"
                data-aos="fade-left"
                data-aos-duration="8000"
              >
                <img
                  src={BookSalon}
                  width="100%"
                  alt="ReserveTable"
                  loading="lazy"
                />
              </div>
            </Row>
            <Row
              className=" my-5 justify-content-between my-5 padding-40-180"
              id="tflGiftCard"
            >
              <Col
                xs={5}
                md={10}
                className="slider d-flex justify-content-center align-items-center"
                data-aos="fade-right"
                data-aos-duration="8000"
              >
                <img
                  alt="tflCoinStill"
                  loading="lazy"
                  src={tflMArket}
                  width={"100%"}
                  style={{ borderRadius: "15px" }}
                />
              </Col>
              <Col
                xs={5}
                md={12}
                data-aos="fade-left"
                data-aos-duration="8000"
                className="about-text d-flex flex-column justify-content-center"
              >
                <h2 className="headerText">
                  <span>DISCOVER AND TRADE</span> IN THE ECO-FRIENDLY MARKET
                </h2>
                <p>
                  Connect with a thriving community that values sustainability.
                  <ul>
                    <li>
                      <b>Browse Unique Finds</b>: From handmade crafts to
                      upcycled treasures, explore items that align with your
                      values.
                    </li>
                    <li>
                      <b>Sell Your Creations</b>: Showcase your eco-conscious
                      products to a growing audience.
                    </li>
                    <li>
                      <b>Sustainable Choices</b>: Choose greener alternatives
                      and reduce waste with every purchase
                    </li>
                  </ul>
                </p>
                <br />
                <u>
                  <i>
                    {" "}
                    <b>Coming Soon...</b>
                  </i>
                </u>
              </Col>
            </Row>
            <Row
              className="my-5 justify-content-between padding-40-180"
              id="reserve"
            >
              <Col
                xs={5}
                md={12}
                data-aos="fade-right"
                data-aos-duration="8000"
                className="col-sm-8 col-md-7 d-flex flex-column justify-content-center"
                style={{ paddingRight: "20px" }}
              >
                <h2>
                  FRESH, LOCAL, AND
                  <span> SUSTAINABLE GROCERIES</span>
                </h2>
                <p>Shop responsibly with our curated grocery selection.</p>
                <ul>
                  <li>
                    <b>Local Produce</b>: Fresh fruits and vegetables from
                    nearby farms.
                  </li>
                  <li>
                    <b>Eco-Friendly Packaging</b>: Say goodbye to single-use
                    plastics.
                  </li>
                  <li>
                    <b>Healthy Choices</b>: Organic, vegan, and wholesome
                    options for all diets.
                  </li>
                </ul>
                <br />
                <br />
                <i>
                  <strong>
                    <u>Coming Soon...</u>
                  </strong>
                </i>
              </Col>

              <Col
                xs={5}
                md={10}
                className="col-sm-3 col-md-5 aboutUs justify-content-end d-flex"
                data-aos="fade-left"
                data-aos-duration="8000"
              >
                <img
                  src={grocerry}
                  width="600px"
                  alt="ReserveTable"
                  loading="lazy"
                />
              </Col>
            </Row> */}
          </Content>
        </>
      )}
      {isMobile && <MobileNavTop />}
      {isMobile && (
        <Container
          fluid
          id="online-header mobileView"
          className="px-0 py-5 my-2"
        >
          {user.token == "" ? (
            <Row className="px-4 pt-2">
              {" "}
              <i>
                {" "}
                For flere fordeler{" "}
                <u>
                  <NavLink to="/signup" className=" ">
                    <b>Registrer</b>
                  </NavLink>
                </u>{" "}
                deg eller{" "}
                <u>
                  <NavLink to="/login" className="">
                    <b>Logg inn</b>
                  </NavLink>
                </u>{" "}
                !!
              </i>
            </Row>
          ) : (
            <Row className="px-4 pt-2">
              Hallo&nbsp;
              <i>
                <b>
                  {user.firstName} {user.lastName},
                </b>
              </i>{" "}
            </Row>
          )}
          <Row justify="center" align="middle" style={{ maxHeight: "200px" }}>
            <Col span={12}></Col>
          </Row>
          <Row className="justify-content-evenly mt-3 smallCard">
            <Col
              span={10}
              className="smallCard1"
              onClick={() => {
                dispatch(rootAction.setOrderType("Drive Out"));
                navigate("/restaurants");
              }}
            />
            <Col
              span={10}
              className="smallCard4"
              onClick={() => navigate("/profile")}
            />
          </Row>
          {/* <Row className="justify-content-evenly mt-3 smallCard">
            <Col span={10} className="smallCard3" />{" "}
            <Col
              span={10}
              className="smallCard2"
              onClick={onOpenGiftCardModal}
            />
          </Row> */}
          <Row className="justify-content-evenly mt-2 smallCard">
            <Col
              span={10}
              className="smallCard5"
              onClick={() => {
                dispatch(rootAction.setOrderType("reserveTable"));
                navigate("/restaurants");
              }}
            />{" "}
            <Col
              span={10}
              className="smallCard6"
              onClick={() => {
                navigate("/tflMarket");
              }}
            />
          </Row>

          {advertisements.length > 0 ? (
            <div className="px-4 pt-3">
              <Carousel
                effect="fade"
                autoplay={true}
                autoplaySpeed={5000}
                ref={carouselRef}
                dots={false}
                className="mt-4"
                //style={{ width: "100vw" }}
              >
                {advertisements.map((data, index) => {
                  return <div key={index}>{renderAd(data)}</div>;
                })}
              </Carousel>
            </div>
          ) : (
            ""
          )}
          <Row style={{ display: "block" }}>
            <Col span={24}>
              {" "}
              <Carousel autoplay className="px-4 py-3">
                {carouselItems.map((item) => (
                  <div
                    key={item.id}
                    className={item.className}
                    onClick={item.onClick}
                    style={{ cursor: "pointer" }}
                  >
                    <div style={contentCarouselStyle}>
                      <h3 style={{ marginBottom: "10px", fontSize: "24px" }}>
                        {item.title}
                      </h3>
                      <p style={{ fontSize: "16px", lineHeight: "1.6" }}>
                        {item.description}
                      </p>
                    </div>
                  </div>
                ))}
              </Carousel>
            </Col>
          </Row>
          <Row className="p-3 bottomThought mb-5">
            <Col span={24}>
              <img src={bellBig} alt="bellBig" loading="lazy" />
            </Col>
            <Col span={24} className="mt-3">
              <p>Mat er den ultimate terapien!</p>
            </Col>
          </Row>
        </Container>
      )}
      {isMobile && <MobileNavBottom />}
      {!isMobile && <BottomFooter />}

      {giftCardModal && <PopUpGiftCard />}

      <ScrollToTop />
    </Layout>
  );
}

export default App;
