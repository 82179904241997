import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { rootAction } from "../../store/slices/rootSlices";
import icon1 from "../../assets/D.png";
import share from "../../assets/A.png";
import giftCard from "../../assets/B.png";
// import icon2 from "../../assets/A.png";
import homeIcon from "../../assets/small.png";
import ProfileIcon from "../../assets/E.png";

import InfoModal from "../../containers/Modal/InfoModal";
import { NavLink } from "react-router-dom";
import PopUpGiftCard from "../../containers/Modal/PopUpGiftCard";

import "aos/dist/aos.css";

export default function MobileNavBarBottom(props) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState("home");
  let user = useSelector((state) => state.userReducer);
  const [gitfCardModal, setgiftCardModal] = useState(false);
  const onOpenGiftCardModal = () => {
    localStorage.setItem("currentPage", "tflGiftModal");
    setgiftCardModal(true);
  };
  const [gitfCardModalToBuy, setgiftCardModalToBuy] = useState(false);
  const onOpenGiftCardModalToBuy = () => {
    setgiftCardModalToBuy(true);
  };
  const closeGiftCardModalToBuy = () => {
    setgiftCardModalToBuy(false);
    setgiftCardModal(false);
  };
  const style = {
    a: {
      color: "1px solid"
    }
  };
  return (
    <>
      <Row className="position-fixed mobileNavBottom px-3 py-2 justify-content-between">
        <Col span={4} className="text-center">
          <a
            onClick={() => {
              setActiveButton("restaurant");
              dispatch(rootAction.setOrderType("Drive Out"));
              navigate("/restaurants");
            }}
            style={activeButton === "restaurant" ? style.a : {}}
            className="d-flex flex-column align-items-center"
          >
            <img src={icon1} />
            <p>Rekkefølge</p>
          </a>
        </Col>{" "}
        <Col span={4} className="text-center">
          {user.token != "" ? (
            <NavLink
              to="/profile?openTab=referralCode"
              className="d-flex flex-column align-items-center"
            >
              <img src={share} />
              Dele
            </NavLink>
          ) : (
            <NavLink
              to="/login"
              className="d-flex flex-column align-items-center"
            >
              <img src={share} />
              Dele
            </NavLink>
          )}
        </Col>{" "}
        <Col span={4} className="text-center">
          <a
            onClick={() => {
              setActiveButton("home");
              setActiveButton("restaurant");
              navigate("/home");
            }}
            style={activeButton === "home" ? style.a : {}}
            className="d-flex flex-column align-items-center"
          >
            <img src={homeIcon} />
            Hjem
          </a>
        </Col>
        {/* <Col span={4} className="text-center">
          <a
            onClick={onOpenGiftCardModal}
            className="d-flex flex-column align-items-center"
          >
            <img src={giftCard} />
            Gavekort
          </a>
        </Col>{" "} */}
        <Col span={4} className="text-center">
          {user.token != "" ? (
            <NavLink
              to="/profile"
              className=" d-flex flex-column align-items-center"
            >
              <img src={ProfileIcon} />
              Profil
            </NavLink>
          ) : (
            <NavLink
              to="/login"
              className="d-flex flex-column align-items-center"
            >
              <img src={ProfileIcon} />
              Profil
            </NavLink>
          )}
        </Col>
      </Row>
      {gitfCardModal && <PopUpGiftCard />}

      {gitfCardModalToBuy && (
        <InfoModal closeGiftCardModalToBuy={closeGiftCardModalToBuy} />
      )}
    </>
  );
}
